<template>
  <div class=" lg:hover:scale-105 transform ease-out duration-200 cursor-pointer hover:opacity-50"
    :class="absolute?'absolute bottom-12 lg:bottom-24':'my-12 lg:my-24'"
    @click="$emit('click')"
  >
    <base-icon :width="checkIsMobile?36:48" :height="checkIsMobile?36:48" color="fill-redAccentLight" name="chevronDownBig" />
  </div>
</template>

<script>
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  props: {
    absolute: Boolean,
  },
  name: "HomepageLogo",
  components: {
    BaseIcon,
  },
  data() {
    return {
      checkIsMobile: checkIsMobileDevice(),
    }
  },
}
</script>