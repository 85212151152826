<template>
  <div class="pt-12 lg:pt-0">
    <div v-scroll-spy="{data: 'div.homepage-selector', offset: checkIsMobile? 120:120}">
      <!-- LOGO -->
      <div class="homepage-selector">
        <div ref="topSection"
          class="relative w-full h-screen flex flex-col justify-center items-center bg-gradient-to-b from-grayed via-white"
        >
          <div class="w-full flex flex-col justify-center items-center">
            <Slogan
              class="font-light opacity-40 lg:opacity-20 pt-16 lg:pt-32"
              text1="PAGE_TITLE:About"
              desktop-size="2xl"
              :mobile-divider=24
              :uppercase=true
              :space-separated=true
            />
            <Slogan
              class="pb-2 lg:pb-6 pt-6"
              text1="LOGO:Segment1"
              text2="LOGO:Segment2"
              :text2-highlighted="true"
              text3="LOGO:Segment3"
              desktop-size="7xl"
              :mobile-divider=9
            />
            <Slogan
              class="font-light"
              text1="LOGO:Slogan3.1"
              text2="LOGO:Slogan3.2"
              :text2-highlighted="true"
              text3="LOGO:Slogan3.3"
              desktop-size="3xl"
              :mobile-divider=18
              :space-separated=true
              :break-on-mobile=true
            />
          </div>
          <HomepageChevron @click="scrollToSection('whatSection')"/>
        </div>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-tiptoes'"/>
      </div>
      <!--  WHAT? -->
      <div class="homepage-selector" ref="whatSection" id="whatSection">
        <HomepageSection>
          <template v-slot:leftTitle>{{ trans('ABOUT:What') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhatText')"/></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalClink"/></template>
        </HomepageSection>
        <HomepageParallax no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-roses'"/>
      </div>
      <!--  WHY? -->
      <div class="homepage-selector" ref="whySection" id="whySection">
        <HomepageSection>
          <template v-slot:leftTitle>{{ trans('ABOUT:Why') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhyText')"/></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalTouch"/></template>
        </HomepageSection>
        <HomepageParallax no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-table'"/>
      </div>
      <!--  HOW WE? -->
      <div class="homepage-selector" ref="howWeSection" id="howWeSection">
        <HomepageSectionMulti>
          <template v-slot:title>{{ trans('ABOUT:HowWe') }}</template>
          <template v-slot:subsections>
            <div v-for="(obj, index) in howWeData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howWeData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageParallax no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-walk'"/>
      </div>
      <!-- HOW TO?  -->
      <div class="homepage-selector" ref="howToSection" id="howToSection">
        <HomepageSectionMulti>
          <template v-slot:title>{{ trans('ABOUT:HowTo') }}</template>
          <template v-slot:subsections>
            <div v-for="(obj, index) in howToData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howToData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageParallax no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-come'"/>
      </div>
      <div class="homepage-selector" ref="authorSection" id="authorSection" >
        <!-- AUTHOR 1 -->
        <HomepageSection>
          <template v-slot:leftTitle>{{ trans('ABOUT:Author') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText1')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalAuthor"/></template>
        </HomepageSection>
        <!-- AUTHOR 2 -->
        <HomepageSection reverse class="lg:pt-28">
          <template v-slot:leftTitle>...</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText2')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalAuthor2" :show="true"/></template>
        </HomepageSection>
        <HomepageParallax no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-author'"/>
      </div>
      <!-- WELCOME -->
      <div ref="sub" id="#join-us"
        class="homepage-selector h-screen w-full flex flex-col justify-center items-center"
      >
        <div class="w-4/5 flex flex-col justify-center items-center pb-24 lg:pg-36">
          <template v-if="isAppLaunched">
            <div class="w-full font-helvNeu text-4xl lg:text-7xl leading-normal text-redAccentLight pb-12 lg:pb-24 text-center">
              {{ trans('ABOUT:SubscribeWelcome') }}
            </div>
            <div v-if="userData">
              <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6">
                <Button
                  variant="buttonRedOutlinedLarge"
                  class="uppercase"
                  :to="{name: 'chats'}"
                  :text="trans('BUTTON:GoToChats')"
                />
                <Button
                  variant="buttonRedLarge"
                  class="uppercase"
                  :to="{name: 'search'}"
                  :text="trans('BUTTON:GoToNewContacts')"
                />
              </div>
            </div>
            <div v-else class="text-center">
              <div class="pb-6 lg:pb-12 leading-normal font-normal text-xl">
                <span v-html="trans('ABOUT:SubscribeWelcomeText')">
                </span>
              </div>
              <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6">
                <Button
                  variant="buttonRedLarge"
                  class="uppercase"
                  :to="{name: 'auth-register'}"
                  :text="trans('BUTTON:Register')"
                />
                <Button
                  variant="buttonRedOutlinedLarge"
                  class="uppercase"
                  :to="{name: 'auth-login'}"
                  :text="trans('BUTTON:LogIn')"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="w-full flex flex-col mx-auto">
              <HomepagePageTitle :text="trans('ABOUT:Subscribe')" />
              <span class="text-center text-xl leading-normal" v-html="trans('ABOUT:SubscribeText')"/>
              <div class="w-full flex justify-center py-8">
                <form @submit.prevent="signToNewsletter" class="w-full lg:w-4/5 flex flex-row gap-4 items-end "
                >
                  <TextField
                    variant="subscribe"
                    field="email"
                    class="w-full"
                    no-error
                    :placeholder="trans('ABOUT:SubscribeEmailPlaceholder')"
                    v-model="email"
                  />
                  <Button
                    variant="buttonRedLarge"
                    class="uppercase"
                    btn-type="submit"
                    @clicked="signToNewsletter"
                    :loading="newsletterLoading"
                    :text="trans('BUTTON:Join')"
                  />
                </form>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'jquery-parallax.js'
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import HomepageImage from "@/views/content/components/Homepage/HomepageImage";
import Slogan from "@/views/content/components/Homepage/Slogan";
import HomepageChevron from "@/views/content/components/Homepage/HomepageChevron";
import HomepagePageTitle from "@/views/content/components/Homepage/HomepagePageTitle";
import HomepageParallax from "@/views/content/components/Homepage/HomepageParallax";
import HomepageSection from "@/views/content/components/Homepage/HomepageSection";
import HomepageSectionMulti from "@/views/content/components/Homepage/HomepageSectionMulti";
import HomepageSectionMultiContent from "@/views/content/components/Homepage/HomepageSectionMultiContent";
import HomepageSectionMultiTitle from "@/views/content/components/Homepage/HomepageSectionMultiTitle";
import TextField from "@/utils/crud/components/field-types/Text";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import themeConfig from "@/themeConfig";
import {isAppLaunched} from "@/common/helpers/utils";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "Homepage",
  components: {
    BaseIcon,
    Button,
    HomepageImage,
    Slogan,
    HomepageChevron,
    HomepagePageTitle,
    HomepageParallax,
    HomepageSection,
    HomepageSectionMulti,
    HomepageSectionMultiContent,
    HomepageSectionMultiTitle,
    TextField
  },
  data() {
    return {
      email: null,
      themeImages: themeConfig.staticPhotos,
      checkIsMobile: checkIsMobileDevice(),
      isAppLaunched: isAppLaunched(),
      howWeChosen: "1",
      howWeChosen2: "2",
      howToChosen: "1",
      howWeData: {},
      howToData: {},
    }
  },
  watch: {
    langLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.$route.query.error) {
            this.error({message: this.trans(`ALERT:${this.$route.query.error}`)})
          }
          if (this.$route.query.success) {
            this.success({message: this.trans(`ALERT:${this.$route.query.success}`)})
          }

          this.howWeData = {
            1: {
              title: this.trans('ABOUT:HowWeTitle1'),
              text: this.trans('ABOUT:HowWeText1'),
            },
            2: {
              title: this.trans('ABOUT:HowWeTitle2'),
              text: this.trans('ABOUT:HowWeText2'),
            },
            3: {
              title: this.trans('ABOUT:HowWeTitle3'),
              text: this.trans('ABOUT:HowWeText3'),
            },
            4: {
              title: this.trans('ABOUT:HowWeTitle4'),
              text: this.trans('ABOUT:HowWeText4'),
            },
            5: {
              title: this.trans('ABOUT:HowWeTitle5'),
              text: this.trans('ABOUT:HowWeText5'),
            },
            6: {
              title: this.trans('ABOUT:HowWeTitle6'),
              text: this.trans('ABOUT:HowWeText6'),
            },
          }

          this.howToData = {
            1: {
              title: this.trans('ABOUT:HowToTitle1'),
              text: this.trans('ABOUT:HowToText1'),
            },
            2: {
              title: this.trans('ABOUT:HowToTitle2'),
              text: this.trans('ABOUT:HowToText2'),
            },
            3: {
              title: this.trans('ABOUT:HowToTitle3'),
              text: this.trans('ABOUT:HowToText3'),
            },
            4: {
              title: this.trans('ABOUT:HowToTitle4'),
              text: this.trans('ABOUT:HowToText4'),
            },
            5: {
              title: this.trans('ABOUT:HowToTitle5'),
              text: this.trans('ABOUT:HowToText5'),
            },
            6: {
              title: this.trans('ABOUT:HowToTitle6'),
              text: this.trans('ABOUT:HowToText6'),
            },
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('newsletter', ['newsletterLoading']),
    ...mapGetters('lang', ['langLoaded']),
  },
  methods: {
    ...mapActions('newsletter', ['signToNewsletterRequest']),
    ...mapActions('alert', ['error', 'success']),

    signToNewsletter() {
      this.signToNewsletterRequest({email: this.email})
    },
    scrollToSection(section) {
      let element = document.getElementById(section);
      let headerOffset = this.checkIsMobile? 120:120;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    $('.parallax-tiptoes').parallax({imageSrc: this.themeImages.aboutWideTiptoes,});
    $('.parallax-author').parallax({imageSrc: this.themeImages.aboutWideAuthor});
    $('.parallax-come').parallax({imageSrc: this.themeImages.aboutWideCome});
    $('.parallax-walk').parallax({imageSrc: this.themeImages.aboutWideWalk});
    $('.parallax-roses').parallax({imageSrc: this.themeImages.aboutWideRoses});
    $('.parallax-table').parallax({imageSrc: this.themeImages.aboutWideTable});

    this.$root.$on('scroll-to-top', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  },
  beforeDestroy() {
    $('.parallax-mirror').remove()
  }
}
</script>

<style lang="scss">
.prlx-img {
  height: 240px;
}

@media (min-width: 1024px) {
  .prlx-img {
    height: 480px;
  }
}

.menu-link {
  &.active-link {
    opacity: 1;
    color: theme('colors.redAccent')
  }
}

</style>