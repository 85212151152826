<template>
  <div class="font-helvNeu text-4xl lg:text-7xl leading-normal text-redAccentLight mb-16 text-center">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "HomepagePageTitle",
  props: {
    text: String,
  }
}
</script>

