<template>
  <div ref="container"
    class="flex flex-col w-full justify-center font-helvNeu"
    :class="[{'uppercase':uppercase}]"
  >
    <p ref="textToResize"
      class="flex lg:flex-row w-full justify-center items-center lg:whitespace-nowrap"
      :class="[
        'lg:text-'+desktopSize,
        breakOnMobile?'flex-col':'flex-row'
      ]"
    >
      <span class="whitespace-nowrap">
        <span :class="[{'text-redAccent':text1Highlighted}]">{{ trans(text1) }}</span>
        <span v-show="text2 && spaceSeparated">&nbsp;</span>
        <span v-show="text2" :class="[{'text-redAccent':text2Highlighted}]">{{ trans(text2) }}</span>
      </span>
      <span class="whitespace-nowrap">
        <span v-show="text2 && text3 && spaceSeparated">&nbsp;</span>
        <span v-show="text2 && text3" :class="[{'text-redAccent':text3Highlighted}]">{{ trans(text3) }}</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "HomepageSlogan1",
  props: {
    text1:String,
    text1Highlighted:Boolean,
    text2:String,
    text2Highlighted:Boolean,
    text3:String,
    text3Highlighted:Boolean,
    uppercase:Boolean,
    spaceSeparated:Boolean,
    desktopSize:String,
    mobileDivider:Number,
    breakOnMobile:Boolean,
  },
  mounted() {
    this.scaleText();
    window.addEventListener('resize', this.scaleText());
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.scaleText());
  },
  methods: {
    scaleText() {
      if(this.$refs.container.clientWidth < 1025) {
        const containerWidth = this.$refs.container.clientWidth;
        let fontSize = containerWidth /this.mobileDivider;
        if (this.breakOnMobile) {
          fontSize = containerWidth /(this.mobileDivider - 5);
        }
          this.$refs.textToResize.style.fontSize = `${fontSize}px`;
      }
    }
  }
}
</script>