<template>
  <t-card variant="homepage">
    <div class="flex flex-col w-full pb-16">
      <div class="text-center text-3xl lg:text-5xl font-helvNeu text-redAccentLight mt-16 lg:mt-0 mb-8 lg:mb-16 mx-auto">
        <slot name="title"/>
      </div>
      <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2">
        <slot name="subsections"/>
      </div>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "HomepageSectionMulti",
}
</script>